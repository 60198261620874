import React from "react"
import Layout from "../components/Layout"
import Menu from "../components/Menu"
import AboutContent from "../components/AboutUs"
import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import General from "../styles/theme"

const AboutPage = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="About Us" />
      <Menu/>
      <AboutContent/>
    </Layout>
  </ThemeProvider>
)

export default AboutPage